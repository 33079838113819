@import "colors";
$small: 1024px;

.section {
  background: $lightGreyBackground;
  font-size: 12px;
  line-height: 24px;
  border-radius: 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px 20px;
  margin-bottom: 20px;
  &.--margin-top {
    margin-top: 20px;
  }
  &.--no-padding {
    padding-left: 0;
    padding-right: 0;
    header {
      margin-left: 20px;
      margin-right: 20px;
    }
    .section-content >  * {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  header {
    position: relative;
    background: none;
    color: $greyText;
  }
  h3 {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
  }

  .empty-data {
    color: rgba(120,120,120, .6);
    text-align: center;
  }

  .toggle-section-visibility {
    position: absolute;
    right: 0;
    top: 7px;
    cursor: pointer;
    transition: all 0.4s ease;
    &.--close {
      transform: rotateZ(180deg);
    }
    &.--mobile-close {
      @media (max-width: $small) {
        transform: rotateZ(180deg);
      }
    }
  }

  .section-content {
    &.--mobile-hidden {
      @media (max-width: $small) {
        display: none;
      }
    }
  }

}


