@import "colors";
#footer {
  text-align: center;
  padding-top: 20px;
  margin-top: auto;
  padding-bottom: 10px;
  .social a {
    font-size: 24px;
    color: chartSky();
    margin: 0 5px;
    display: inline-block;
  }
  .links {
    padding: 2px 0;
    font-size: 12px;
    a:hover {
      text-decoration: underline;
    }
    span {
      font-weight: bold;
      font-size: 10px;
      color: #39c2d2;
    }
  }
  .copyright {
    color: $greyText2;
    font-style: italic;
    font-size: 10px;
  }
}
