#page {
  width: 100%;
  padding: 0 10px;
  overflow: auto;
}

.container.--new #page {
  padding-bottom: 10px;
}

/*********************************  HEADER  **********************************/
#header {
  background: #18212b;
  height: 56px;
  -webkit-border-bottom-right-radius: 12px;
  -moz-border-radius-bottomright: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
}
#header > * {
  width: 33.33%;
}
@media (max-width: 880px) {
  #header > *.spacer {
    width: 4%;
  }
}

#home {
  background: #18212b url("../images/v2/logoWhite.png") no-repeat center center;
  background-size: 140px;
  width: 140px;
  text-indent: -9999em;
  height: 56px;
}

#userBlock {
  color: #fff;
}
#userBlock a {
  color: #fff;
}

#userLinks {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
#userLinks .name {
  font-weight: bold;
}

#openUserMenu {
  padding-right: 40px;
  line-height: 14px;
  font-size: 12px;
}
#openUserMenu:before {
  float: right;
  margin-left: 30px;
  font-size: 18px;
}
#openUserMenu > * {
  display: inline-block;
  margin-left: 20px;
}

#userMenu {
  display: none;
  min-width: 145px;
  position: absolute;
  background: #18212b;
  z-index: 50;
  width: auto;
  right: 30px;
  top: 43px;
  border-top: 1px solid #444444;
}
#userMenu.active {
  display: block;
  z-index: 10000;
}
#userMenu a {
  display: block;
  padding: 7px;
  border-bottom: 1px solid #444444;
  font-size: 0.8em;
  text-align: left;
}
#userMenu a:hover {
  text-decoration: underline;
}

/******************************  MAIN MENU  ********************************/
#mainMenu {
  min-height: calc(100vh - 56px);
  background: #242e38;
  color: #d5d5d5;
  margin-top: -10px;
  transition: all 0.5s ease;
}
#mainMenu .item {
  white-space: nowrap;
  transition: all 0.5s ease;
}
#mainMenu .item:first-child a {
  border-top: none;
}
#mainMenu .item a {
  overflow: hidden;
  color: #d5d5d5;
  background-color: #2a3644;
  border-bottom: 1px solid #171c21;
  border-top: 1px solid #3c4754;
  text-transform: uppercase;
  position: relative;
  display: block;
  padding: 15px 0 15px 10px;
  transition: all 0.3s ease;
}
#mainMenu .item a:hover {
  color: rgb(190, 240, 224);
  transition: all 0.3s ease;
}
#mainMenu .item a:hover .button-icon {
  transition: all 0.3s ease;
  background-color: rgba(190, 240, 224, 0.5);
}
#mainMenu .item span {
  vertical-align: middle;
}
#mainMenu .item span + span {
  transition: all 0.5s ease;
}
#mainMenu .item .button-icon {
  display: inline-block;
  font-size: 18px;
  margin-right: 10px;
}
#mainMenu .item .value {
  background: rgba(238, 52, 50, 0.75);
  left: 3px;
  top: 10px;
  color: #fff;
  text-align: center;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  min-width: 25px;
  line-height: 14px;
  position: absolute;
  font-weight: 500;
}
#mainMenu .item .value:empty {
  display: none;
}
#mainMenu > div > a:before {
  font-size: 18px;
  line-height: 25px;
  color: #FFFFFF;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  float: left;
}
#mainMenu .submenu {
  position: relative;
}
#mainMenu .submenu ul {
  display: none;
  position: absolute;
  width: 200px;
  top: 0;
  z-index: 52;
}
#mainMenu .submenu ul a {
  height: 10px;
  min-height: 10px;
  line-height: 10px;
  font-size: 0.9em;
  background-color: #2a3644;
  border-bottom: 1px solid #171c21;
  border-top: 1px solid #3c4754;
  border-left: 1px solid #3c4754;
  border-bottom: none;
  padding: 9.5px 8px;
}
#mainMenu .submenu:hover ul {
  display: block;
}
#mainMenu.--full {
  width: 220px;
}
#mainMenu.--full .item {
  width: 220px;
}
#mainMenu.--full .item span + span {
  visibility: visible;
}
#mainMenu.--full .submenu ul {
  left: 220px;
}
#mainMenu.--full .submenu > a {
  background-image: url("../images/v2/pictos/submenu.png");
  background-repeat: no-repeat;
  background-position: right center;
}
#mainMenu.--light {
  min-width: auto;
  width: 50px;
}
#mainMenu.--light .item {
  width: 50px;
}
#mainMenu.--light .item span + span {
  visibility: hidden;
}
#mainMenu.--light .submenu ul {
  left: 50px;
}
#mainMenu.--light .submenu > a {
  background-image: none;
}
#mainMenu #nbContactsToTreat {
  background: rgba(247, 180, 9, 0.75);
}
#mainMenu #nbContactsToTreat.--warning {
  background: rgba(238, 52, 50, 0.75);
}

@media (max-width: 1024px) {
  #mainMenu {
    min-width: auto;
    width: 50px;
  }
  #mainMenu .item {
    width: 50px;
  }
  #mainMenu .item span + span {
    visibility: hidden;
  }
  #mainMenu .submenu ul {
    left: 50px;
  }
  #mainMenu .submenu > a {
    background-image: none;
  }
}
@media (min-width: 1025px) {
  #mainMenu {
    width: 220px;
  }
  #mainMenu .item {
    width: 220px;
  }
  #mainMenu .item span + span {
    visibility: visible;
  }
  #mainMenu .submenu ul {
    left: 220px;
  }
  #mainMenu .submenu > a {
    background-image: url("../images/v2/pictos/submenu.png");
    background-repeat: no-repeat;
    background-position: right center;
  }
}
#toggleMainMenu {
  width: 30px;
  height: 30px;
  display: block;
  margin-left: 10px;
}
#toggleMainMenu .button-icon {
  font-size: 18px;
}