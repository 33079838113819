.section {
  background: #f3f3f3;
  font-size: 12px;
  line-height: 24px;
  border-radius: 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px 20px;
  margin-bottom: 20px;
}
.section.--margin-top {
  margin-top: 20px;
}
.section.--no-padding {
  padding-left: 0;
  padding-right: 0;
}
.section.--no-padding header {
  margin-left: 20px;
  margin-right: 20px;
}
.section.--no-padding .section-content > * {
  padding-left: 20px;
  padding-right: 20px;
}
.section header {
  position: relative;
  background: none;
  color: #3a3939;
}
.section h3 {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
}
.section .empty-data {
  color: rgba(120, 120, 120, 0.6);
  text-align: center;
}
.section .toggle-section-visibility {
  position: absolute;
  right: 0;
  top: 7px;
  cursor: pointer;
  transition: all 0.4s ease;
}
.section .toggle-section-visibility.--close {
  transform: rotateZ(180deg);
}
@media (max-width: 1024px) {
  .section .toggle-section-visibility.--mobile-close {
    transform: rotateZ(180deg);
  }
}
@media (max-width: 1024px) {
  .section .section-content.--mobile-hidden {
    display: none;
  }
}