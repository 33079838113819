#footer {
  text-align: center;
  padding-top: 20px;
  margin-top: auto;
  padding-bottom: 10px;
}
#footer .social a {
  font-size: 24px;
  color: rgb(0, 1, 107);
  margin: 0 5px;
  display: inline-block;
}
#footer .links {
  padding: 2px 0;
  font-size: 12px;
}
#footer .links a:hover {
  text-decoration: underline;
}
#footer .links span {
  font-weight: bold;
  font-size: 10px;
  color: #39c2d2;
}
#footer .copyright {
  color: #777;
  font-style: italic;
  font-size: 10px;
}