@import "colors";
#home {
  margin: auto;
}
#loginFormBody {

  display: flex;
  flex-direction: column;
  min-height: 100%;
  #loginForm {
    width: 630px;
    max-width: 90%;
    margin: auto;
    flex: 1; /* Cela permet au contenu de s'étendre pour remplir l'espace disponible en hauteur */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }
  .section {
    line-height: normal;
    &:not(.--bottom) {
      padding-bottom: 40px;
    }
    header {
      margin-bottom: 40px;
    }
  }
  input[type=text], input[type=email], input[type=password] {
    box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, .45);
    border: none;
    background-color: #fff;
    padding: 5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 34px;
  }
}

.login-fields {
  div {
    width: 96%;
    &.email {
      margin-right: 2%;
    }
    &.password-field {
      margin-left: 2%;
    }
  }
  input {
    margin: 0 10px;
  }
}
.password-field {
  position: relative;
  input {
    padding-right: 27px;
  }
  .toggle-password {
    position: absolute;
    font-size: 16px;
    right: 0;
    top: 5px;
    border: none;
    cursor: pointer;
    width: 25px;
    height: 25px;
    &:before {
      top: 0px;
      position: absolute;
      right: 5px;
    }
  }
}
.login-fields {
  .toggle-password {
    right: -10px
  }
}
.external {
  margin-bottom: 20px;
  a {
    padding: 10px;
    width: 50px;
    height: 50px;
    img {
      width: 100%;
    }
  }
}
input::placeholder {
  color: #D3D3D3;
}
.password {
  color: chartSky(1);
  font-weight: bold;
}
.--bottom {
  text-align: center;
  padding-top: 10px;
  .button-default {
    margin: 20px auto;
    width: 170px;
    height: 24px;
  }
  a.button-default {
    padding-top: 5px;
    height: 17px;
    font-size: 13px;
  }
}
.--center {
  text-align: center;
}

.errors {
  color: $red;
  font-weight: bold;
  margin-bottom: 20px;
}
.--reset {
  input {
    margin-bottom: 20px;
  }
  .errors {
    margin-left: 25px;
  }
}
.resetting_message_block {
  color: $greyText2;
  text-align: left;
  width: 50%;
  margin-left: 5%;
  &.--red {
    color: $red;
  }
  ul {
    list-style-type: disc;
    margin-left: 40px;
    li {
      &.--error {
        color: $red;
      }
    }
  }
}

.password-fields {
  width: 57%;
}

@media screen and (max-width: 680px) {
  .password-fields {
    width: 100%;
  }
  .login-fields {
    display: block;
    input {
      margin-bottom: 20px;
    }
  }
  .--reset {
    div{
      display: flex;
      flex-direction: column;
    }
    .errors {
      margin-left: 0;
    }
  }
  .resetting_message_block {
    font-size: 11px;
    margin: auto;
  }
  .--bottom {
    padding-top: 0;
  }
}
