@import "colors";
$small: 880px;

#page {
  width: 100%;
  padding: 0 10px;
  overflow: auto
}
.container.--new {
  #page {
    padding-bottom: 10px;
  }
}


/*********************************  HEADER  **********************************/
#header {
  background: $darkBlue;
  height: 56px;
  -webkit-border-bottom-right-radius: 12px;
  -moz-border-radius-bottomright: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  & > * {
    width: 33.33%;
    @media (max-width: $small) {
      &.spacer {
        width: 4%;
      }
    }
  }
}
#home {
  background: $darkBlue url('../images/v2/logoWhite.png') no-repeat center center;
  background-size: 140px;
  width: 140px;
  text-indent: -9999em;
  height: 56px;
}

#userBlock {
  color: #fff;
  a {
    color: #fff;
  }
}
#userLinks {
  position: relative;
  display: inline-block;
  vertical-align:middle;
  width: 100%;
  .name {
    font-weight: bold;
  }
}
#openUserMenu {
  padding-right: 40px;
  line-height: 14px;
  font-size: 12px;
  &:before {
    float: right;
    margin-left: 30px;
    font-size: 18px;
  }
  & > * {
    display: inline-block;
    margin-left: 20px;
  }
}
#userMenu {
  display: none;
  min-width: 145px;
  position: absolute;
  background: $darkBlue;
  z-index: 50;
  width: auto;
  right: 30px;
  top: 43px;
  border-top: 1px solid #444444;
  &.active {
    display: block;
    z-index: 10000;
  }
  a {
    display: block;
    padding:7px;
    border-bottom: 1px solid #444444;
    font-size: 0.8em;
    text-align:left;
    &:hover {
      text-decoration: underline;
    }
  }
}

/******************************  MAIN MENU  ********************************/

@mixin light {
  min-width: auto;
  width: 50px;
  .item {
    width: 50px;
    span + span {
      visibility: hidden;
    }
  }
  .submenu {
    ul {
      left: 50px;
    }
    & > a {
      background-image: none;
    }
  }
}
@mixin full {
  width: 220px;

  .item {
    width: 220px;
    span + span {
      visibility: visible;
    }
  }
  .submenu {
    ul {
      left: 220px;
    }
    & > a {
      background-image: url('../images/v2/pictos/submenu.png');
      background-repeat: no-repeat;
      background-position: right center;
    }
  }
}

#mainMenu {
  min-height: calc(100vh - 56px);
  background: #242e38;
  color: #d5d5d5;
  margin-top: -10px;
  transition: all 0.5s ease;

  .item {
    white-space: nowrap;
    transition: all 0.5s ease;
    &:first-child a {
      border-top: none;
    }
    a {
      overflow: hidden;
      color: #d5d5d5;
      background-color: #2a3644;
      border-bottom: 1px solid #171c21;
      border-top: 1px solid #3c4754;
      text-transform: uppercase;
      position: relative;
      display: block;
      padding: 15px 0 15px 10px;
      transition: all 0.3s ease;
      &:hover {
        color: rgba(190, 240, 224,1);
        transition: all 0.3s ease;
        .button-icon {
          transition: all 0.3s ease;
          background-color: rgba(190, 240, 224, .5);
        }
      }
    }
    span {
      vertical-align: middle;
      & + span {
        transition: all 0.5s ease;
      }
    }
    .button-icon {
      display: inline-block;
      font-size: 18px;
      margin-right: 10px;
    }
    .value {
      background: rgba(238, 52, 50, .75);
      left: 3px;
      top: 10px;
      color: #fff;
      text-align: center;
      -webkit-border-radius: 25px;
      border-radius: 25px;
      min-width: 25px;
      line-height: 14px;
      position: absolute;
      font-weight: 500;
      &:empty {
        display: none;
      }
    }
  }
  & > div > a:before {
    font-size: 18px;
    line-height: 25px;
    color:#FFFFFF;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    float: left;
  }
  .submenu {
    position: relative;
    ul {
      display: none;
      position: absolute;
      width: 200px;
      top: 0;
      z-index: 52;
      a {
        height: 10px;
        min-height: 10px;
        line-height: 10px;
        font-size: .9em;
        background-color: #2a3644;
        border-bottom: 1px solid #171c21;
        border-top: 1px solid #3c4754;
        border-left: 1px solid #3c4754;
        border-bottom: none;
        padding: 9.5px 8px;
      }
    }
    &:hover ul {
      display: block;
    }
  }

  &.--full {
    @include full;
  }
  &.--light {
    @include light;
  }

  #nbContactsToTreat {
    background: rgba(247, 180, 9, .75);
    &.--warning {
      background: rgba(238, 52, 50, .75);
    }
  }
}

@media (max-width: 1024px) {
  #mainMenu {
    @include light;

  }
}

@media (min-width: 1025px) {
  #mainMenu {
    @include full;
  }
}
#toggleMainMenu {
  width: 30px;
  height: 30px;
  display: block;
  margin-left: 10px;
  .button-icon {
    font-size: 18px;
  }
}
