.switch-container {
  margin: 0 10px;
  &.--disabled input + .slider {
    background-color: rgba(204, 204, 204, .4);
    cursor: default;
  }
  &.--disabled input:checked + .slider {
    background-color: rgba(0, 1, 107, .4);
  }
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  &.--always {
    background-color: rgba(0, 1, 107, 1) !important;
  }
  &.--bg-blue {
    background-color: #2196F3 !important;
  }
  &.--bg-clear-blue {
    background-color: #658af8 !important;
  }
  &.--bg-pink {
    background-color: #ff91cf !important;
  }
  &.--bg-green {
    background-color: #48874A !important;
  }
  &.--bg-red {
    background-color: #D11818!important;
  }
  &:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
}
input {
  &:checked + .slider {
    background-color: rgba(0, 1, 107, 1);
  }
  &:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  &:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
}
/* Rounded sliders */
.slider {
  &.round {
    border-radius: 34px;
    &:before {
      border-radius: 50%;
    }
  }
}