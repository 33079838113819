.switch-container {
  margin: 0 10px;
}
.switch-container.--disabled input + .slider {
  background-color: rgba(204, 204, 204, 0.4);
  cursor: default;
}
.switch-container.--disabled input:checked + .slider {
  background-color: rgba(0, 1, 107, 0.4);
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  /* Hide default HTML checkbox */
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider.--always {
  background-color: rgb(0, 1, 107) !important;
}
.slider.--bg-blue {
  background-color: #2196F3 !important;
}
.slider.--bg-clear-blue {
  background-color: #658af8 !important;
}
.slider.--bg-pink {
  background-color: #ff91cf !important;
}
.slider.--bg-green {
  background-color: #48874A !important;
}
.slider.--bg-red {
  background-color: #D11818 !important;
}
.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: rgb(0, 1, 107);
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}